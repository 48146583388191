import axios from 'axios';
import type { ChatSearchResult, Column, OpenDatasetResultDto, Dataset, DatasetDto, DatasetLifecycleEvent, DatasetProperty, DatasetVersion, HealthCheckResponse, OpenDataFilterOptions, PostgresCredentials, SearchRequest, UserMetadata, DatasetFolder, DatasetAutoRefreshConfig } from '@/types';

export function useDatasetService() {
    const config = useRuntimeConfig();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api`,
        headers: useRequestHeaders(['cookie']),
    })

    async function createDataset(dataset: Partial<Dataset>) {
        const { data } = await api.post<Dataset>(`/datasets/`, dataset);
        return data;
    }

    async function updateDataset(dataset: Partial<Dataset>) {
        const { data } = await api.patch<Dataset>(`/datasets/${dataset.id}`, dataset);
        return data;
    }

    async function moveDataset(datasetId: string, newParentFolderId?: string | null) {
        const { data } = await api.patch<Dataset>(`/datasets/${datasetId}`, { DatasetFolderId: newParentFolderId });
        return data;
    }

    async function getDatasetVersionEvents(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<DatasetLifecycleEvent[]>(`/datasets/${datasetId}/versions/${datasetVersionId}/events`);
        return data;
    }

    async function deleteDataset(datasetId: string) {
        const { data } = await api.delete<Dataset>(`/datasets/${datasetId}`);
        return data;
    }

    async function createDatasetVersion(datasetId: string, datasetVersion: Partial<DatasetVersion>) {
        const { data } = await api.post<DatasetVersion>(`/datasets/${datasetId}/versions`, datasetVersion);
        return data;
    }

    async function createDatasetVersionUploadLink(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get(`/datasets/${datasetId}/versions/${datasetVersionId}/upload-link`);
        return data;
    }

    async function completeUserDatasetVersionUpload(datasetId: string, datasetVersionId: string) {
        const { data } = await api.post(`/datasets/${datasetId}/versions/${datasetVersionId}/upload-complete`);
        return data;
    }

    async function updateDatasetUserMetadata(datasetId: string, metadata: Partial<UserMetadata>) {
        const { data } = await api.patch(`/datasets/${datasetId}/user-metadata`, metadata);
        return data;
    }

    async function getUserDatasets(query: string,) {
        const { data } = await api.get<Array<{ dataset: Dataset, version: DatasetVersion }>>(`/datasets/user-datasets`, {
            params: {
                query,
            }
        });

        return data;
    }

    async function getUserDatasetsAndFolders(query: string): Promise<DatasetDto> {
        const { data } = await api.get<DatasetDto>(`/datasets/user-datasets`, {
            params: {
                query,
                version: "v2"
            }
        });
        return data;
    }

    async function getDataset(id: string, includeVersions = true): Promise<Dataset> {
        const { data } = await api.get<Dataset>(`/datasets/${id}`, {
            params: {
                includeVersions: includeVersions ? 'true' : 'false'
            }
        });
        console.log(`getDataset`, data);
        return data;
    }

    async function getDatasetVersions(id: string): Promise<DatasetVersion[]> {
        const { data } = await api.get<DatasetVersion[]>(`/datasets/${id}/versions`);
        return data;
    }

    async function getDatasetVersion(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<DatasetVersion>(`/datasets/${datasetId}/versions/${datasetVersionId}`);
        return data;
    }

    async function getLatestDatasetVersionForOpenData(datasetId: string) {
        const { data } = await api.get<DatasetVersion>(`/datasets/${datasetId}/latest-version`);
        return data;
    }

    async function getDatasetVersionColumns(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<Column[]>(`/datasets/${datasetId}/versions/${datasetVersionId}/columns`);
        return data;
    }

    async function getDatasetVersionInsights(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<any>(`/datasets/${datasetId}/versions/${datasetVersionId}/insights`);

        return data;
    }

    async function getSample(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<any[]>(`/datasets/${datasetId}/versions/${datasetVersionId}/sample?size=20`, {
        });
        return data;
    }


    async function getDatasetDataUrls(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<{ url: string }>(`/datasets/${datasetId}/versions/${datasetVersionId}/data`);
        return data.url;
    }

    async function premakeFilterBasedOnQuery(datasetId: string, datasetVersionId: string, query: string) {
        const { data } = await api.post<Record<string, string[]>>(`/datasets/${datasetId}/versions/${datasetVersionId}/premake-filter`, {
            query,
            datasetId,
            datasetVersionId,
        });
        return data;
    }

    async function searchOpenDatasets(options: OpenDataFilterOptions, limit = 20, offset = 0, sortBy = 'relevance', optimizeQuery = false) {
        const { data } = await api.post<OpenDatasetResultDto>(`/datasets/open-data/search`, options, {
            params: {
                query: options.query,
                limit,
                offset,
                sortBy,
                optimizeQuery: optimizeQuery ? 'yes' : 'no',
            }
        });
        return data;
    }



    async function chatSearchDatasets(options: OpenDataFilterOptions, sortBy = 'publishedAt', strictMode = true): Promise<ChatSearchResult> {
        const { data } = await api.post<ChatSearchResult>(`/datasets/chat-search`, options, {
            params: {
                query: options.query,
                sortBy,
                strictMode,
            }
        });
        return data;
    }

    async function getChatSearchResult(id: string) {
        // /open-data/chat-search/result/:id'
        const { data } = await api.get<ChatSearchResult>(`/datasets/chat-search/result/${id}`);
        return data;
    }

    async function getChatSearchResultRelevantQueries(query: string) {
        // query in the body
        // POST to /open-data/chat-search/relevant-queries`

        const { data } = await api.post<string[]>(`/datasets/chat-search/relevant-queries`, { query });

        return data;
    }


    async function getTrendingThemes() {
        const { data } = await api.get<any>(`/datasets/open-data/trending-themes`);
        return data;
    }

    async function getSeriesDatasets(provider: string, code: string) {
        const { data } = await api.get<Array<{ dataset: Dataset, version: DatasetVersion }>>(`/datasets/open-data/series-datasets`, {
            params: {
                provider,
                code
            }
        });
        return data;
    }

    async function getStarredDatasets() {
        const { data } = await api.get<Array<{ dataset: Dataset, version: DatasetVersion }>>(`/datasets/stars`);
        return data;
    }

    async function starDataset(id: string) {
        const { data } = await api.post(`/datasets/stars`, { id });
        return data;
    }

    async function getLatestDatasetsByThemes(themes: string[], limit = 20) {
        const { data } = await api.get<Record<string, Array<{ dataset: Dataset, version: DatasetVersion, insightCount: number }>>>(`/datasets/open-data/latest?themes=${themes.join(',')}?limit=${limit}`)

        return data;
    }

    async function getLatestDatasetsByProviders(providers: string[]) {
        const { data } = await api.get<Record<string, Array<{ dataset: Dataset, version: DatasetVersion, insightCount: number }>>>(`/datasets/open-data/latest?providers=${providers.join(',')}`)

        return data;
    }

    async function getRecentInsights() {
        const { data } = await api.get<Dataset>(`/datasets/insights/latest`);
        return data;
    }

    async function getDatasetVersionAnalysisIdeas(datasetId: string, datasetVersionId: string) {
        const { data } = await api.get<any>(`/datasets/${datasetId}/versions/${datasetVersionId}/analysis-ideas`);
        return data;
    }

    async function searchOpenDatasetSuggestions(query: string) {
        const { data } = await api.post<{ results: string[] }>(`/datasets/open-data/suggestions?query=${query}`, {});
        return data;
    }

    async function getSearchHistory() {
        const { data } = await api.get<SearchRequest[]>(`/datasets/open-data/search-history`);
        return data;
    }

    async function addDatasetVersionProperty(datasetId: string, datasetVersionId: string, property: Partial<DatasetProperty>) {
        const { data } = await api.post<any>(`/datasets/${datasetId}/versions/${datasetVersionId}/properties`, property);
        return data;
    }

    async function getDatasetVersionProperties(datasetId: string, datasetVersionId: string, keys: string[]) {
        const { data } = await api.get<any>(`/datasets/${datasetId}/versions/${datasetVersionId}/properties`, {
            params: {
                keys: keys.join(',')
            }
        });
        return data;
    }

    async function getHealth() {
        const { data } = await api.get<HealthCheckResponse>(`/health`);
        return data;
    }

    async function updateDatasetVersion(datasetId: string, datasetVersionId: string, datasetVersion: Partial<DatasetVersion>) {
        const { data } = await api.patch<DatasetVersion>(`/datasets/${datasetId}/versions/${datasetVersionId}`, datasetVersion);
        return data;
    }

    async function setDatasetVersionColumns(datasetId: string, datasetVersionId: string, columns: Array<Partial<Column>>) {
        const { data } = await api.patch<DatasetVersion>(`/datasets/${datasetId}/versions/${datasetVersionId}/columns`, columns);
        return data;
    }

    async function getDatasetPermissions(datasetId: string) {
        const { data } = await api.get<any>(`/datasets/${datasetId}/permissions`);
        return data;
    }

    async function createDatasetPermission(datasetId: string, props: any) {
        const { data } = await api.post<{ id: string }>(`/datasets/${datasetId}/permissions`, props);
        return data;
    }

    async function deleteDatasetPermission(datasetId: string, permissionId: string) {
        const { data } = await api.delete(`/datasets/${datasetId}/permissions/${permissionId}`);
        return data;
    }

    async function sendDatasetVersionToQueue(datasetId: string, datasetVersionId: string, queue: string) {
        const { data } = await api.post(`/datasets/${datasetId}/versions/${datasetVersionId}/message`, {

        }, {
            params: {
                target: queue
            }
        });
        return data;
    }


    async function updateDatasetVersionColumns(datasetId: string, datasetVersionId: string, columns: Array<Partial<Column>>) {
        const { data } = await api.patch(`/datasets/${datasetId}/versions/${datasetVersionId}/columns`, columns);
        return data;
    }

    ///:id/versions/:version/columns/request-metadata
    async function getDatasetVersionColumnsRequestMetadata(datasetId: string, datasetVersionId: string) {
        await api.post<void>(`/datasets/${datasetId}/versions/${datasetVersionId}/columns/request-metadata`);
    }

    async function deleteColumn(datasetId: string, datasetVersionId: string, columnId: string) {
        await api.delete<void>(`/datasets/${datasetId}/versions/${datasetVersionId}/columns/${columnId}`);
    }

    async function createFolder(folder: Partial<DatasetFolder>): Promise<DatasetFolder> {
        const { data } = await api.post<DatasetFolder>('/datasets/folders', folder);
        return data;
    }

    async function deleteFolder(folderId: string) {
        await api.delete(`/datasets/folders/${folderId}`);
    }

    async function updateFolder(folderId: string, folder: Partial<DatasetFolder>): Promise<DatasetFolder> {
        const { data } = await api.patch<DatasetFolder>(`/datasets/folders/${folderId}`, folder);
        return data;
    }

    async function moveFolder(folderId: string, newParentFolderId?: string | null): Promise<DatasetFolder> {
        const { data } = await api.patch<DatasetFolder>(`/datasets/folders/${folderId}`, { DatasetFolderId: newParentFolderId });
        return data;
    }

    async function setDatasetCredentials(datasetId: string, credentials: Partial<PostgresCredentials>) {
        const { data } = await api.put<PostgresCredentials>(`/datasets/${datasetId}/credentials`, credentials);
        return data;
    }

    async function getDatasetCredentials(datasetId: string) {
        const { data } = await api.get<PostgresCredentials>(`/datasets/${datasetId}/credentials`);
        return data;
    }

    async function getDatasetConnectionEntities(datasetId: string) {
        const { data } = await api.post<string[]>(`/datasets/${datasetId}/credential-check`);
        return data;
    }

    async function setDatasetAutoRefreshConfig(datasetId: string, config: Partial<DatasetAutoRefreshConfig>) {
        const { data } = await api.put(`/datasets/${datasetId}/auto-refresh-config`, config);
        return data;
    }


    return {
        setDatasetAutoRefreshConfig,
        getDatasetCredentials,
        setDatasetCredentials,
        getDatasetConnectionEntities,
        deleteColumn,
        getDatasetVersionColumnsRequestMetadata,
        updateDatasetVersionColumns,
        sendDatasetVersionToQueue,
        updateDatasetVersion,
        getHealth,
        addDatasetVersionProperty,
        createDataset,
        getDatasetVersionEvents,
        getDatasetVersionProperties,
        createDatasetVersion,
        createDatasetVersionUploadLink,
        completeUserDatasetVersionUpload,
        getUserDatasets,
        getUserDatasetsAndFolders,
        deleteDataset,
        updateDataset,
        moveDataset,
        updateDatasetUserMetadata,
        getDataset,
        getSample,
        getDatasetDataUrls,
        getDatasetVersion,
        getDatasetVersionColumns,
        getDatasetVersionInsights,
        getDatasetVersions,
        searchOpenDatasets,
        getSeriesDatasets,
        getStarredDatasets,
        starDataset,
        getChatSearchResultRelevantQueries,
        getLatestDatasetsByThemes,
        getLatestDatasetsByProviders,
        getRecentInsights,
        getDatasetVersionAnalysisIdeas,
        searchOpenDatasetSuggestions,
        getTrendingThemes,
        getSearchHistory,
        premakeFilterBasedOnQuery,
        chatSearchDatasets,
        getChatSearchResult,
        getLatestDatasetVersionForOpenData,
        setDatasetVersionColumns,
        getDatasetPermissions,
        createDatasetPermission,
        deleteDatasetPermission,
        createFolder,
        deleteFolder,
        updateFolder,
        moveFolder
    }
}